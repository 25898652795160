<template>
  <div class="payment-page">
    <div class="informModal" :class="{ 'show': isShowModal }">
      <div class="informModalBg"></div>
      <div class="informContent">
        <div class="informHeader">通知</div>
        <div class="informText">
          <p>非官方最新版本无法支付，<br>请下载官方包继续支付。</p>
        </div>
        <div class="informBtn">
          <span class="informKnow" @click="goDowload">去下载</span>
        </div>
      </div>
    </div>
    <div class="smsModal" :class="{ 'show': isShowSmsModal }">
      <div class="smsModalBg"></div>
      <div class="smsContent">
        <div class="smsModalHeader">
          <i class="smsBack" @click="isShowSmsModal = false"></i>请输入验证码
        </div>
        <div class="smsForm">
          <div class="smsIput">
            <input type="tel" v-model="codeValue" id="code" maxlength="6" />
            <div class="smsCode" :class="codeClass" @click="smsCodeAction">{{ codeText }}</div>
          </div>
          <div class="smsText">验证码已发送到<span>{{ smsText }}</span>，此手机号为绑定的唯一手机号</div>
        </div>
        <div class="smsSure" @click="surePay">确认支付</div>
      </div>
    </div>
    <div class="container">
    <div class="payContent">
      <p class="payMoney">￥ {{payMoneyValue}}</p>
      <div class="changeMultiple payPurpose1001">
        <p class="btnMap">
          <span class="minus" @click="changeMultiple('reduce')">-</span>
          <span class="multiple">{{ multiple }}</span>
          <span class="plus" @click="changeMultiple('plus')">+</span>
        </p>
        <p class="payMax" @click="changeMultiple('max')">最高倍数</p>
      </div>
      <div class="payPurpose">
        <p>{{ payPurposeText }}</p>
      </div>
    </div>
    <div class="payMentList">
      <h1 class="payTitle">支付方式</h1>
      <!-- <div class="paymentRow"
        :class="{ 'select': (payId === 0 && isBalance) }" @click="selectPay(0)"
        :style="styleBc"
        v-if="goodsId !== 'blindbox_activity_2'">
        <img class="paymentIcon" src="./images/jzpay.png">
        <p class="paymentName">有鱼零钱（<span class="pinMoney">{{ accountMoney }}</span>）</p>
      </div> -->
      <div class="paymentRow" :class="{ 'select': payId === 1 }"  @click="selectPay(1)">
        <img class="paymentIcon" src="./images/alipay.png">
        <p class="paymentName">支付宝支付</p>
      </div>
    </div>
    <div class="payNotice">
      <div class="payPurpose1001" v-show="payPurpose === 1001">
        <div>支付前须知：</div>
        <p class="rules">1、参与金翻倍，若挑战成功奖金也相应翻倍！普通用户最高10倍，<span>会员支持最高20倍</span>。</p>
        <p class="rules">2、参与记账分钱活动，若挑战失败会失去所有本金。</p>
      </div>
      <div class="payPurpose1002" v-show="payPurpose === 1002">
        <div>购买后即可下载并使用皮肤。</div>
      </div>
    </div>
    <div class="payBtn">
      <p @click="payAction">支付</p>
    </div>
  </div>
  <!-- <div v-html="formData"></div> -->
  </div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import { getUrlParam, isChinese } from '@/utils';
import {
  sendSmsApi,
  payApi,
  generatorMailOrderApi,
  generatorRecoverDataOrderApi,
  queryUserAccountSmallChangeApi,
} from '@/api/base1';

const jumpUrl = getUrlParam('jumpUrl') || '';
const aliBack = getUrlParam('charset') || '';
let accountMoney = 0;
let multiple = 1;
let perMoney = getUrlParam('payMoney') || sessionStorage.getItem('perMoney') || '10';
let userType = sessionStorage.getItem('userType') || 0;

let payPurpose = getUrlParam('payPurpose') || '';
const payPurposeText = {
  1001: '记账分钱挑战本金', // 预留未启用
  1002: '购买主题皮肤',
  1003: '购买记账会员',
  1004: '导出数据',
  1005: '数据恢复',
  1006: '打赏',
};
const beginDate = getUrlParam('beginDate') || '';
const endDate = getUrlParam('endDate') || '';
const email = getUrlParam('email') || '';
let scenes = 0; // 支付场景 0：皮肤  1：购买会员 2:导出数据 3：数据恢复 4：打赏
const backId = getUrlParam('backId') || '';
let memo = getUrlParam('memo') || ''; // 订单号
const goodsId = getUrlParam('goodsId') || ''; // 购买的商品id,(皮肤，会员 ，打赏必传)
const couponId = getUrlParam('couponId'); // 优惠卷ID
const orderId = getUrlParam('orderId') || ''; // 订单号

export default {
  name: 'Payment',
  data() {
    return {
      isShowModal: false,
      payId: 1,
      codeClass: '',
      codeText: '发送',
      isShowSmsModal: false,
      payPurpose: Number(payPurpose || 0),
      payPurposeText: payPurposeText[payPurpose] || '其他',
      payMoneyValue: '---',
      multiple: 1,
      codeValue: '',
      accountMoney: 0.00,
      smsText: '',
      isBalance: false,
      formData: '',
      isPaying: false, // 是否支付中
    };
  },
  mounted() {
    document.title = '支付';
    this.initData();
  },
  computed: {
    isHuawei() {
      return getAppStatus.isHuawei;
    },
    cuserId() {
      // return 'bf72fa3c-86f6-450c-adf6-128d36caf104';
      return getAppStatus().cuserId;
    },
    source() {
      return getAppStatus().source;
    },
    releaseVersion() {
      return getAppStatus().releaseVersion;
    },
    header() {
      const { cuserId, source, releaseVersion } = this;
      return {
        cuserId,
        source,
        releaseVersion,
      };
    },
    queryData() {
      return this.$route.query;
    },
    goodsId() {
      return this.queryData.goodsId;
    },
    styleBc() {
      let res = {};
      if (this.isBalance) {
        res = {
          background: '#f5f5f5',
          opacity: '.5',
        };
      }
      return res;
    },
  },
  methods: {
    initData() {
      const { cuserId } = this;
      let {
        activityId, jumpUrl, memo, orderId,
        payMoney, payType, scenes,
      } = this.queryData;
      // aliBack && this.afterJoinOpenBtn();
      // cuserId && this.getPinMoney();
      payPurpose = Number(payPurpose || 0);
      if (payPurpose !== 1001) {
        this.payMoneyValue = payMoney;
        scenes = payPurpose - 1002 || 0;
      } else { // 记账分钱
        userType = Number(userType);
        multiple = userType === 0 ? 1 : 10; // 倍数
        this.multiple = multiple;
        // eslint-disable-next-line radix
        this.payMoneyValue = parseInt(perMoney * multiple).toFixed(2);
      }
    },
    changeMultiple(status) {
      userType = Number(userType);
      if (status === 'reduce') {
        if (multiple <= 1) {
          this.$toast({ content: '倍数不能再低啦' });
        } else {
          multiple--;
        }
      }
      if (status === 'plus') {
        if (multiple >= 10 && !userType) {
          return this.$toast({ content: '非会员用户最高只能10倍哟~' });
        }
        if (multiple >= 20) {
          return this.$toast({ content: '倍数不能再高了哟~' });
        }
        multiple++;
      }
      if (status === 'max') {
        multiple = userType ? 20 : 10;
      }
      this.multiple = multiple;
      // eslint-disable-next-line radix
      this.payMoneyValue = parseInt(perMoney * multiple).toFixed(2);
    },
    goDowload() {
      window.location.href = '//jz.yofish.com';
    },
    payAction() {
      const { payId } = this;
      if (this.isPaying) return false;
      if (this.isHuawei) {
        this.isShowModal = true;
        return false;
      }
      this.isPaying = true;
      if (payId === 0) { // 零钱支付
        if (perMoney * multiple > accountMoney) {
          return this.$toast({ content: '零钱余额不足，请选择其他支付方式' });
        }
        this.isShowSmsModal = true;
        this.sendSms();
      } else { // 支付宝支付
        this.goPay(0);
      }
    },
    surePay() {
      const { codeValue } = this;
      if (codeValue.length === 6) {
        this.goPay(1, codeValue);
      } else {
        this.$toast({ content: '请输入验证码' });
      }
    },
    selectPay(id) {
      if (accountMoney > perMoney * multiple || id === 1) {
        this.payId = id;
      }
    },
    async sendSms() {
      let res;
      const data = {
        smsType: 0,
      };
      const { cuserId, source, releaseVersion } = this;
      const header = {
        cuserId,
        source,
        releaseVersion,
      };
      try {
        res = await sendSmsApi(data, header);
      } catch (e) {
        console.log(e);
      }
      if (res.code !== 1) {
        return this.$toast({ content: res.desc });
      }
      this.$toast({ content: '验证码已发送' });
      this.codeClass = 'wait';
    },
    startSms() {
      let interval = null;
      let num = 60;
      this.codeText = num + 's';
      interval = setInterval(() => {
        num--;
        if (num <= 0) {
          clearInterval(interval);
          this.codeText = '\u91CD\u53D1';
          this.codeClass = '';
        } else {
          this.codeText = num + 's';
        }
      });
    },
    smsCodeAction() {
      this.codeClass !== 'wait' && this.sendSms();
    },
    /*
   * @param {支付类型} payType
   * @param {验证码} smsCode
   */
    async goPay(payType, smsCode) {
      let res;
      let { scenes, jumpUrl, activityId } = this.queryData;
      this.formData = '';
      const params = {
        payMoney: Number(this.payMoneyValue),
        payType, // 0 支付宝 1：零钱
        smsCode: smsCode || '', // 验证码 payType=1必传
        jumpUrl: payType === 0 ? jumpUrl : '', // 跳转链接 payType=0必传
        scenes: Number(scenes), // 支付场景 0：皮肤 1：购买会员 2:导出数据 3：数据恢复 4：打赏
        goodsId: this.goodsId, // 购买的商品id,(皮肤，会员必传)
        orderId, // 订单id ：scenes=2和3必传(需要先调用对应的生成订单接口)
        memo, // 备注 ：scenes=4必传
      };
      if (couponId) {
        params.couponId = couponId; // 优惠卷ID
      }
      if (activityId) params.activityId = Number(activityId);
      let appInfo;
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        appInfo = window?.ios?.jzAppInfo();
      } else if (/android/i.test(navigator.userAgent)) {
        appInfo = window?.android?.jzAppInfo();
      } else {
        appInfo = {
          name: 'youyu',
          channel: '官网',
          version: '5.1.2',
          osType: 1,
        };
        appInfo = JSON.stringify(appInfo);
      }
      appInfo = JSON.parse(appInfo);
      let { channel } = appInfo;
      let name = isChinese(appInfo.name) ? encodeURIComponent(appInfo.name) : appInfo.name;
      channel = isChinese(channel) ? encodeURIComponent(channel) : channel;
      // let name = encodeURIComponent(appInfo.name);
      // channel = encodeURIComponent(channel);
      let osType = appInfo.osType || 2;
      const { cuserId, source, releaseVersion } = this;
      const headers = {
        cuserId,
        source,
        releaseVersion,
        name,
        channel,
        osType,
      };
      try {
        res = await payApi(params, headers);
      } catch (e) {
        console.log(e);
        this.isPaying = false;
      }
      this.isPaying = false;
      if (res?.code !== 1) {
        // return this.$toast({ content: res?.desc || '网络异常' });
      }
      try {
        const patt = /<input.*name="biz_content"[^>]+value=['"]([^'"]+)['"]+/g;
        let temp = patt.exec(res.results);
        if (temp && temp.length) {
          let arr = temp[1].replace(/&quot;/g, '"');
          arr = JSON.parse(arr);
          let outTradeNo = arr.out_trade_no;
          localStorage.setItem('orderId', outTradeNo);
          sessionStorage.setItem('orderId11', outTradeNo);
          sessionStorage.setItem('goodsId', this.goodsId);
          // window?.ios?.vipOrderNum(outTradeNo);
        }
      } catch (e) {
        console.log(e);
      }
      if (payType === 0) {
        // document.body.appendChild(res.results || null);
        // this.formData = res.results || '';
        this.formData = res.results;
        $('body').append(this.formData || null);
      } else {
        this.isShowSmsModal = false;
        this.afterJoinOpenBtn();
      }
    },
  },
  beforeDestroy() {
    this.formData = '';
    $('form').remove();
  },
  afterJoinOpenBtn() {
    try {
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        couponId && window?.ios?.jzClose();
        if (!couponId) {
          this.$toast({ content: '支付成功~' });
          setTimeout(() => {
            location.href = jumpUrl;
          }, 3000);
        }
      }
      if (/android/i.test(navigator.userAgent)) {
        payPurpose = Number(payPurpose || 0);
        if (payPurpose === 1001) { // 记账分钱
          window?.android?.postEvent('winMoney');
        }
        if (payPurpose === 1002) { // 购买皮肤
          window?.android?.postEvent('buySkinSuccess');
        }
        if (payPurpose === 1004) { // 数据导出
          window?.android?.postEvent('exportData');
        }
        if (payPurpose === 1005) { // 恢复数据
          window?.android?.postEvent('recoverData');
        }
        window?.android?.finishWebActivity();
        this.$toast({ content: '支付成功~' });
      }
    } catch (e) {
      console.log(e);
    }
  },
  async mailOrder() {
    let res;
    const { header, cuserId } = this;
    try {
      res = await generatorMailOrderApi({
        cuserId,
        beginDate,
        endDate,
        email,
      }, header);
    } catch (e) {
      console.log(e);
    }
    if (res?.code === 1) {
      // eslint-disable-next-line no-const-assign
      orderId = res?.results?.orderId;
    } else {
      this.$toast({ content: res?.desc || '网络异常' });
    }
  },
  checklogin(follow) {
    const { cuserId } = this;
    const that = this;
    if (cuserId) {
      this.checkLoginstatus((flag) => {
        (flag !== -1 && !flag) && this.loginAction();
        follow();
      });
    }
  },
  loginAction() {
    try {
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        window?.ios?.jzLogInIfNeeded();
      }
      if (/android/i.test(navigator.userAgent)) {
        window?.android?.goToActivity2('jz://com.jz.youyu/com.caiyi.accounting.jz.LoginsActivity?doNormalJump=false');
      }
    } catch (error) {
      console.log('调用客户端失败：' + error);
    }
  },
  async getPinMoney() { // 查询零钱
    let res;
    const { header, cuserId } = this;
    try {
      res = await queryUserAccountSmallChangeApi({}, header);
    } catch (e) {
      console.log(e);
    }
    if (res?.code === 1) {
      accountMoney = res?.results?.accountMoney || 0;
      this.accountMoney = accountMoney;
      this.smsText = res?.results?.mobileNo || '';
      this.isBalance = accountMoney < perMoney * multiple;
    }
  },
  checkLoginstatus(follow) {
    let flag = -1;
    try {
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        flag = window?.ios?.jzIsLogin();
        follow(flag);
      }
      if (/android/i.test(navigator.userAgent)) {
        flag = window?.android?.isUserRegistered();
        follow(flag);
      }
    } catch (e) {
      follow(flag);
      console.log(e);
    }
  },
};
</script>
<style lang="scss">
  @import url('./scss/index.scss');
  @media only screen and (device-width: 375px) and
  (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .payBtn {
      bottom: 34px;
      margin-bottom: 34px !important;
    }

    .paySelModalContent {
      padding-bottom: 34px !important;
    }
  }
</style>
